import React from 'react';

export const translationValues = {
  link1: msg => (
    <a href="https://www.f-musiikki.fi/" target="_blank" rel="noreferrer">
      {msg}
    </a>
  ),
  link2: msg => (
    <a href="https://www.instagram.com/luisromerotoyos/" target="_blank" rel="noreferrer">
      {msg}
    </a>
  ),
  link3: msg => (
    <a href="https://molotowfinland.fi/" target="_blank" rel="noreferrer">
      {msg}
    </a>
  ),
  link4: msg => (
    <a href="mailto:alakertabar@gmail.com" target="_blank" rel="noreferrer">
      {msg}
    </a>
  ),
  link5: msg => (
    <a href="https://www.olarinpanimo.fi/" target="_blank" rel="noreferrer">
      {msg}
    </a>
  ),
  link6: msg => (
    <a href="https://www.olvi.fi/" target="_blank" rel="noreferrer">
      {msg}
    </a>
  ),
  link7: msg => (
    <a href="https://hdco.fi/" target="_blank" rel="noreferrer">
      {msg}
    </a>
  ),
  link8: msg => (
    <a href="https://ruokaaovelle.com/" target="_blank" rel="noreferrer">
      {msg}
    </a>
  ),
  link9: msg => (
    <a href="https://play.google.com/store/apps/details?id=fi.foodapp.ruokaa" target="_blank" rel="noreferrer">
      {msg}
    </a>
  ),
  link10: msg => (
    <a href="https://apps.apple.com/kw/app/ruokaaovelle-com/id1545132878" target="_blank" rel="noreferrer">
      {msg}
    </a>
  ),
  link11: msg => (
    <a href="https://altiagroup.com/" target="_blank" rel="noreferrer">
      {msg}
    </a>
  )
}
