import React, { Fragment } from "react"
import AboutSection from "./AboutSection"
import { ALAKERTA_DARK_BACKGROUND, ALAKERTA_GREEN } from "../util/constants"
import styled from "@emotion/styled"
import DividerIcon from "./DividerIcon"
import { useStaticQuery, graphql } from "gatsby"

const AboutStyle = styled.div`
  background: linear-gradient(${ALAKERTA_GREEN}, ${ALAKERTA_DARK_BACKGROUND});
  padding: 1.5rem 0;
  min-height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`

const aboutSections = [
  {
    name: "drinks",
    titleId: "about_section.drink.title",
    descriptionId: "about_section.drink.description",
    imageAltText: "A refreshing mojito on the bar of Bar Alakerta",
  },
  {
    name: "music",
    titleId: "about_section.music.title",
    descriptionId: "about_section.music.description",
    imageAltText: "Musicians performing in Bar Alakerta",
  },
  {
    name: "art",
    titleId: "about_section.art.title",
    descriptionId: "about_section.art.description",
    imageAltText:
      "Piece of art pictured in Bar Alakerta depicting space and planets",
  },
  {
    name: "food",
    titleId: "about_section.food.title",
    descriptionId: "about_section.food.description",
    imageAltText: "Man cooking some food",
  },
]

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      music: file(relativePath: { eq: "about_sections/music.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }

      art: file(relativePath: { eq: "about_sections/art.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }

      food: file(relativePath: { eq: "about_sections/food.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }

      drinks: file(relativePath: { eq: "about_sections/drinks.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <AboutStyle>
      {aboutSections.map(section => {
        return (
          <Fragment key={section.name}>
            <AboutSection
              titleId={section.titleId}
              descriptionId={section.descriptionId}
              imageData={data}
              sectionName={section.name}
              imageAltText={section.imageAltText}
            />
            <DividerIcon />
          </Fragment>
        )
      })}
    </AboutStyle>
  )
}

export default About
