import React from "react"
import ContentPane from "./ContentPane"
import { css } from "@emotion/core"
import {
  ALAKERTA_RED,
  ALAKERTA_LIGHT_YELLOW,
  ALAKERTA_DARKER_RED,
  ALAKERTA_DARK_YELLOW,
  SECONDARY_FONT
} from "../util/constants"
import Img from "gatsby-image"
import { FormattedMessage } from "react-intl"
import { translationValues } from '../util/translationValues';

const titleStyle = css`
  color: black;
  background: linear-gradient(90deg, ${ALAKERTA_LIGHT_YELLOW}, ${ALAKERTA_DARK_YELLOW} 50%, rgba(0, 0, 0, 0));
  display: inline-block;
  width: 60%;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  font-family: ${SECONDARY_FONT};
  text-transform: uppercase;
`

const descriptionStyle = css`
  color: ${ALAKERTA_LIGHT_YELLOW};
  // to render line breaks in translations
  white-space: pre-line;
`

const AboutSection = ({ titleId, sectionName, imageAltText, imageData, descriptionId }) => {
  return (
    <ContentPane
      primaryColor={ALAKERTA_RED}
      secondaryColor={ALAKERTA_DARKER_RED}
    >
      <h2 className="about-section-title" css={titleStyle}>
        <FormattedMessage
          id={titleId}
        />
      </h2>
      <Img
        fluid={imageData[sectionName].childImageSharp.fluid}
        alt={imageAltText}
        className="about-section-image"
      />
      <div className="about-category-description" css={descriptionStyle}>
        <p>
          <FormattedMessage id={descriptionId} values={translationValues} />
        </p>
      </div>
    </ContentPane>
  )
}

export default AboutSection;
